import { useApolloClient } from "@apollo/client"
import { useEffect, useState } from "react"
import { createContainer } from "unstated-next"
import CLEAR_REPORTS from "./clearReports.graphql"
import FLAG from "./flag.graphql"
import GENERATE_TUTOR_COMMENT from "./generateTutorComment.graphql"
import GET_CONTENT from "./getContent.graphql"
import GET_WRITING_COUNT from "./getWritingCount.graphql"
import PUBLISH from "./publish.graphql"
import TRY_AGAIN from "./tryAgain.graphql"
import UNPUBLISH from "./unpublish.graphql"
import UPDATE from "./update.graphql"

const useWritingFeed = () => {
  const client = useApolloClient()
  const [writings, setWritings] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const [loading, setLoading] = useState(false)
  const [writingCount, setWritingCount] = useState(0)
  const [loadingWritingCount, setLoadingWritingCount] = useState(true)
  const [pageSize, setPageSize] = useState(0)

  const [filters, setFilters] = useState(null)
  const [feed, setFeed] = useState(null)

  useEffect(() => {
    const pageSize = parseInt(localStorage.getItem("pageSize"), 10)
    if (pageSize > 0) {
      setPageSize(pageSize)
    } else {
      setPageSize(15)
    }
  }, [])

  useEffect(() => {
    if (pageSize > 0) {
      localStorage.setItem("pageSize", pageSize)
      setHasMore(true)
      setWritings([])
    }
  }, [pageSize])

  const getWritingCount = async () => {
    if (feed) return 0
    if (!filters) return 0
    setLoadingWritingCount(true)
    const { data } = await client.query({
      query: GET_WRITING_COUNT,
      variables: {
        filters,
      },
      fetchPolicy: "network-only",
    })
    setWritingCount(data.tutoring.counts.writings)
    setLoadingWritingCount(false)
  }

  useEffect(() => {
    getWritingCount()
  }, [filters])

  const loadMore = async () => {
    if (pageSize <= 0) return null
    if (!filters) return null

    setLoading(true)
    const { data } = await client.query({
      query: GET_CONTENT,
      variables: {
        skip: 0,
        limit: pageSize,
        filters,
        feed,
      },
      fetchPolicy: "network-only",
    })
    const newWritings = data.tutoring.writings
      .map(w => ({ ...w }))
      .sort((a, b) => a.updatedAt - b.updatedAt)
    setWritings([...writings, ...newWritings])
    if (newWritings.length < pageSize) {
      setHasMore(false)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (writings.length === 0 && hasMore) {
      loadMore(0)
    }
    // eslint-disable-next-line
  }, [writings])

  const clearReports = async _id => {
    const { data } = await client.mutate({
      mutation: CLEAR_REPORTS,
      variables: {
        writingId: _id,
      },
    })
    if (data.clearReports) {
      setWritings(
        [...writings].map(d => (d._id !== _id ? d : { ...d, reports: [] }))
      )
    }
  }

  const unpublish = async _id => {
    const { data } = await client.mutate({
      mutation: UNPUBLISH,
      variables: {
        taskType: "writing",
        taskId: _id,
      },
    })
    if (data.putAsPending) {
      setWritings([...writings].filter(d => d._id !== _id))
      // incrementPendingWriting(1)
    }
  }

  const publish = async (_id, blogFeature = false) => {
    const { data } = await client.mutate({
      mutation: PUBLISH,
      variables: {
        writingId: _id,
        blogFeature,
      },
    })
    if (data.publishWriting) {
      setWritings(writings => [...writings].filter(d => d._id !== _id))
    }
  }

  const publishAll = async () => {
    return Promise.all(
      [...writings].map(w => {
        return publish(w._id)
      })
    )
  }

  const tryAgain = async _id => {
    const { data } = await client.mutate({
      mutation: TRY_AGAIN,
      variables: {
        writingId: _id,
      },
    })
    if (data.archiveWriting) {
      setWritings([...writings].filter(d => d._id !== _id))
    }
  }

  const tryAgainAll = async () => {
    return Promise.all(
      [...writings].map(w => {
        return tryAgain(w._id)
      })
    )
  }

  const updatePrivacy = async (_id, value) => {
    const { data } = await client.mutate({
      mutation: UPDATE,
      variables: {
        writing: {
          _id,
          private: value
        }
      }
    })
    if (data && data.updateWriting && data.updateWriting.private !== value) {
      alert('Privacy update failed')
    } else {
      console.log('set writings')
      setWritings(
        [...writings].map(d =>
          d._id !== _id ? { ...d } : { ...d, private: value }
        )
      )
    }
  }

  const flag = async _id => {
    const { data } = await client.mutate({
      mutation: FLAG,
      variables: {
        writingId: _id,
      },
    })
    if (data.rejectWriting) {
      setWritings([...writings].filter(d => d._id !== _id))
    }
  }

  const flagAll = async () => {
    return Promise.all(
      [...writings].map(w => {
        return flag(w._id)
      })
    )
  }

  const feature = async writing => {
    if (writing.status !== "published") {
      return publish(writing._id, true)
    }
    const { data } = await client.mutate({
      mutation: UPDATE,
      variables: {
        writing: { _id: writing._id, blogFeatured: true },
      },
    })
    if (data.updateWriting) {
      setWritings(
        [...writings].map(d =>
          d._id !== writing._id ? { ...d } : { ...d, blogFeatured: true }
        )
      )
    }
  }

  const updateFilters = payload => {
    setFeed(null)
    setFilters({ ...filters, ...payload })
    setWritings([])
    setHasMore(true)
  }
  
  useEffect(() => {
    if (feed) {
      setWritings([])
      setHasMore(true)
    }
  }, [feed])

  const toggleStatus = value => {
    let newStatus = []
    if (filters.statusIn.indexOf(value) >= 0) {
      newStatus = filters.statusIn.filter(d => d !== value)
    } else {
      newStatus = [...filters.statusIn, value]
    }
    updateFilters({ statusIn: newStatus })
  }

  const unfeature = async _id => {
    const { data } = await client.mutate({
      mutation: UPDATE,
      variables: {
        writing: { _id, blogFeatured: false },
      },
    })
    if (data.updateWriting) {
      setWritings(
        [...writings].map(d =>
          d._id !== _id ? { ...d } : { ...d, blogFeatured: false }
        )
      )
    }
  }

  const updateWritingAtIndex = async (i, payload) => {
    const newWritings = [...writings]
    newWritings[i] = { ...newWritings[i], ...payload }
    setWritings(newWritings)
  }

  const removeCommentWithId = async (writingIndex, commentId) => {
    const comments = [...writings[writingIndex].comments].filter(
      d => d._id !== commentId
    )
    updateWritingAtIndex(writingIndex, { comments })
  }

  const addCommentToWriting = async (writingId, comment) => {
    const newWritings = [...writings].map(w =>
      w._id === writingId
        ? {
            ...w,
            comments: [...w.comments, comment],
          }
        : { ...w }
    )
    setWritings(newWritings)
  }

  const generateTutorComment = async writingId => {
    const shouldGenerate = window.confirm("Generate tutor comment?")
    if (!shouldGenerate) return
    const { data } = await client.mutate({
      mutation: GENERATE_TUTOR_COMMENT,
      variables: { writingId },
    })
    addCommentToWriting(writingId, data.generateTutorComment)
  }

  return {
    pageSize,
    setPageSize,
    writings,
    feed,
    writingCount,
    loadingWritingCount,
    loading,
    loadMore,
    hasMore,
    unpublish,
    flag,
    flagAll,
    tryAgain,
    tryAgainAll,
    feature,
    unfeature,
    publish,
    filters,
    setFeed,
    updateFilters,
    toggleStatus,
    publishAll,
    removeCommentWithId,
    addCommentToWriting,
    generateTutorComment,
    clearReports,
    updatePrivacy
  }
}

export default createContainer(useWritingFeed)
