import React, { useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../../theme'
import Button from '../../UI/Button'
import WritingFeedState from '../useWritingFeed'

const Wrapper = styled.div`
  > :first-child {
    font-size: 20px;
    font-weight: bold;
    line-height: 20px;
    margin-bottom: 10px;
  }
  > :nth-child(2) {
    > * {
      margin-bottom: 10px;
    }
    > :last-child {
      margin-bottom: 0px;
    }
  }
  margin-left: auto;
`

const Actions = () => {
  const { publishAll, flagAll, tryAgainAll, feed } = WritingFeedState.useContainer()
  const [publishing, setPublishing] = useState(false)

  const onPublishAll = async () => {
    if (publishing) return
    setPublishing(true)
    try {
      await publishAll()
    } catch (err) {
      console.error(err)
    }
    setPublishing(false)
  }

  const onFlagAll = async () => {
    if (publishing) return
    setPublishing(true)
    try {
      await flagAll()
    } catch (err) {
      console.error(err)
    }
    setPublishing(false)
  }

  const onTryAgainAll = async () => {
    if (publishing) return
    setPublishing(true)
    try {
      await tryAgainAll()
    } catch (err) {
      console.error(err)
    }
    setPublishing(false)
  }

  if (feed) return null
return <Wrapper>
    <div>Batch Actions</div>
    <div>
      <Button bgColor={publishing ? colors.lightGray : colors.green} onClick={onPublishAll}>Publish</Button>
      <Button bgColor={publishing ? colors.lightGray : colors.gray} onClick={onTryAgainAll}>Try Again</Button>
      <Button bgColor={publishing ? colors.lightGray : colors.red} onClick={onFlagAll}>Flag</Button>
    </div>
  </Wrapper>
}

Actions.propTypes = {

}

Actions.defaultProps = {

}

export default Actions
