import { Calendar, CheckBox, TextInput } from 'grommet'
import React, { useEffect, useState } from 'react'
import { FormattedDate } from "react-intl"
import styled from 'styled-components'
import { colors } from '../../../theme'
import WritingFeedState from '../useWritingFeed'

const Wrapper = styled.div`
  margin-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
  > * { margin-right: 25px }
`

const Status = styled.div`
  > :first-child {
    font-size: 20px;
    font-weight: bold;
    line-height: 20px;
    margin-bottom: 10px;
  }
  > div {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    > :first-child { margin-right: 8px; }
  }
`

const SelectedDate = styled.div`
  position: relative;
  height: 42px;
  display: flex;
  align-items: center;
  background-color: ${colors.indigo};
  border-radius: 8px;
  margin-top: 10px;
  padding: 0 12px;
  color: #fff;
  > :first-child {
    cursor: pointer;
    width: 100%;
  }
`

const StyledCalendar = styled(Calendar)`
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  color: #000;
  padding: 15px;
  width: 100%;
  width: auto;
  transform: translateY(50px);
`

const Feeds = styled.div`
  display: flex;
  align-items: flex-start;
  > * { margin-right: 10px; }
  > :last-child { margin-right: 0; }
`

const Feed = styled.div`
  border: 1px solid #000;
  color: #000;
  padding: 4px 8px; 
  font-size: 14px;
  cursor: pointer;
  background-color: ${props => props.selected ? '#0075D6' : '#E8E8E8'};
`

const WordFilters = styled.div`
  > * { width: 70px; }
`

const PRESETS = {
  REPORTED: {
    id: 'reported',
    title: 'Reported',
    filters: {
      shouldReceiveAutoComment: null,
      gibberish: {},
      dangerousLanguage: {},
      profanity: {},
      updated: null,
      statusIn: ['pending', 'published'],
      nbReports: { from: 1 }
    }
  },
  SENSITIVE: {
    id: 'sensitive',
    title: 'Review Carefully',
    filters: {
      statusIn: ['pending'],
      createdAt: { from: `2023-08-01` },
      profanity: { eq: 1 },
      dangerousLanguage: { eq: 1 },
      gibberish: { eq: 0 },
      updated: null,
      writingPictures: false,
      shouldReceiveAutoComment: null,
      nbReports: undefined
    }
  },
  BANNED_THEMES: {
    id: 'banned-themes',
    title: 'Banned Themes',
    filters: {
      statusIn: ['pending'],
      profanity: { eq: 0 },
      dangerousLanguage: { eq: 0 },
      personalInfo: { eq: 0 },
      updated: null,
      bannedTheme: { eq: 1 },
      gibberish: { eq: 0 },
      writingPictures: false,
      shouldReceiveAutoComment: null,
      nbReports: undefined
    }
  },
  PERONAL_INFO: {
    id: 'personal-info',
    title: 'Personal Info',
    filters: {
      statusIn: ['pending'],
      profanity: { eq: 0 },
      dangerousLanguage: { },
      bannedTheme: { },
      updated: null,
      personalInfo: { eq: 1 },
      gibberish: { eq: 0 },
      writingPictures: false,
      shouldReceiveAutoComment: null,
      nbReports: undefined
    }
  },
  GIBBERISH: {
    id: 'gibberish',
    title: 'Gibberish',
    filters: {
      statusIn: ['pending'],
      profanity: { eq: 0 },
      dangerousLanguage: { eq: 0 },
      gibberish: { eq: 1 },
      updated: false,
      writingPictures: false,
      shouldReceiveAutoComment: null,
      nbReports: undefined

    }
  },
  UPDATED: {
    id: 'updated',
    title: 'Previously Published and Edited',
    filters: {
      statusIn: ['pending'],
      dangerousLanguage: { eq: 0 },
      updated: true,
      profanity: { },
      gibberish: { },
      writingPictures: false,
      shouldReceiveAutoComment: null,
      nbReports: undefined

    }
  },
  AUTO_COMMENTS: { 
    id: 'autoComments',
    title: 'With Autocomments',
    filters: {
      statusIn: ['pending'],
      updated: false,
      profanity: { eq: 0 },
      gibberish: { eq: 0 },
      writingPictures: false,
      dangerousLanguage: { eq: 0 },
      shouldReceiveAutoComment: true,
      nbReports: undefined

    }
  },
  NO_AUTOCOMMENTS: {
    id: 'noAutoComments',
    title: 'Without Autocomments',
    filters: {
      statusIn: ['pending'],
      updated: false,
      profanity: { eq: 0 },
      gibberish: { eq: 0 },
      writingPictures: false,
      dangerousLanguage: { eq: 0 },
      shouldReceiveAutoComment: false,
      nbReports: undefined
    }
  },
  PICTURES: {
    id: 'pictures',
    title: 'Pictures',
    filters: {
      statusIn: ['pending'],
      updated: undefined,
      profanity: undefined,
      gibberish: undefined,
      dangerousLanguage: undefined,
      writingPictures: true,
      shouldReceiveAutoComment: undefined,
      nbReports: undefined
    }
  },
  AUTO_COMMENT_ISSUE: {
    id: 'autoCommentIssue',
    title: 'Comment Missing',
    filters: {
      statusIn: ['published'],
      updated: false,
      profanity: { eq: 0 },
      gibberish: { eq: 0 },
      writingPictures: false,
      dangerousLanguage: { eq: 0 },
      shouldReceiveAutoComment: true,
      hasPendingAutoComment: true,
      nbReports: undefined

    }
  },
  FEATURABLE: {
    id: 'featurable',
    title: 'Featurable',
    feed: 'FEATURABLE'
  }
}

const Filters = () => {
  const { filters, updateFilters, setFeed, feed } = WritingFeedState.useContainer()

  const fiveMonthAgo = new Date(Date.now() - 31 * 5 * 24 * 60 * 60 * 1000)
  const [calendarDisplayed, setCalendarDisplayed] = useState(false)
  const [date, setDate] = useState(fiveMonthAgo)
  const [preset, setPreset] = useState(PRESETS.AUTO_COMMENTS)

  useEffect(() => {
    setCalendarDisplayed(false)
    updateFilters({
      createdAt: { from: `${date}` },
      updatedAt: { from: `${date}` }
    })
    // eslint-disable-next-line
  }, [date])

  useEffect(() => {
    if (preset.id === 'featurable') {
      setFeed(preset.feed)
    } else {
      setFeed(null)
      updateFilters({ ...preset.filters })
    }
    // eslint-disable-next-line
  }, [preset])

  return <Wrapper>
      <Feeds>
        {
          Object.keys(PRESETS).map(p => PRESETS[p]).map(p => <Feed key={p.id} selected={preset.id === p.id} onClick={() => setPreset(p)}>
            {p.title}
          </Feed>)
        }
      </Feeds>
      { !feed && <>
        { filters &&
          <Status>
          <div>Words</div>
          <div><CheckBox checked={filters?.wordCount} onChange={(e) =>
            updateFilters({ wordCount: e.target.checked ? { from: 0, to: 30 } : undefined })
          } /> Enabled</div>
          { filters?.wordCount && <WordFilters>
            <TextInput initialValue={filters.wordCount.from} onBlur={e => updateFilters({ wordCount: { ...filters.wordCount, from: parseInt(e.target.value, 10)}})} />
            <TextInput initialValue={filters.wordCount.to} onBlur={e => updateFilters({ wordCount: { ...filters.wordCount, to: parseInt(e.target.value, 10)}})} />
          </WordFilters> }
        </Status> }
        <Status>
        <SelectedDate>
          { /* eslint-disable-next-line */ }
              <div onClick={() => setCalendarDisplayed(!calendarDisplayed)}>
                <FormattedDate value={date} />
              </div>
              {calendarDisplayed && (
                <StyledCalendar
                  size="small"
                  date={date}
                  onSelect={date => setDate(date)}
                />
              )}
            </SelectedDate>
        </Status>
      </>}
    </Wrapper>
  }

Filters.propTypes = {

}

Filters.defaultProps = {

}

export default Filters
