import { navigate } from 'gatsby'
import { Button as GrommetButton, TextInput } from 'grommet'
import { Chat, Checkmark, Revert, Star, Trash, Flag } from 'grommet-icons'
import React from 'react'
import styled from 'styled-components'
import { colors } from '../../theme'
import EditableComment from '../EditableComment'
import EditableWriting from '../EditableWriting'
import Loader from '../UI/Loader'
import NewComment from '../Writing/components/NewComment'
import Actions from './components/Actions'
import Filters from './components/Filters'
import WritingFeedState from './useWritingFeed'
import { Button } from 'nzk-react-components'

const Wrapper = styled.div`
  > :first-child {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
  }
  > :last-child {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
  }
`

const IconButton = styled(GrommetButton)`
  display: flex;
  align-items: center;
  > :first-child { margin-right: 8px; }
`

const Content = styled.div`
  display: grid;
  grid-template-columns: minmax(calc(100vw - 204px), 1fr);
  grid-gap: 25px;
  > * > * > * {
  }
  > * > :first-child > * {
    border: none;
  }
`

const WritingWrapper = styled.div`
  display: grid;
  background-color: white;
  grid-template-columns: minmax(calc(100vw - 204px), 1fr);
  grid-gap: 10px;
  > :first-child {
    border-radius: 0;
  }
  h3 {
    margin: 0;
    padding-left: 10px;
    font-family: 'Roboto';
    font-weight: bold;
    font-size: 17px;
  }
`

const CommentsWrapper = styled.div`
  > :last-child > :first-child {
    margin-bottom: 0px;
  }
`

const CommentWrapper = styled.div`
  margin-bottom: 0px;
    z-index: ${props => 500 - props.index};
  > :first-child {
    margin-bottom: 20px;
  }
  > * > :first-child {
    border-radius: 0;
  }
`

const GenerateAutoCommentButtonContainer = styled.div`
  margin-top: 10px;
`

const NewCommentWrapper = styled.div`
  padding: 10px;
`

const PageSize = styled.div`
  display: flex;
  align-items: center;
  > :first-child {
    width: 44px;
    input {
      border: none;
      font-weight: bold;
      padding: 5px 10px;
    }
  }
`

const AllWritingFeed = () => {
  const { loadingWritingCount, pageSize, setPageSize, writingCount, writings, loading, unpublish, flag, feature, unfeature, publish, removeCommentWithId, addCommentToWriting, tryAgain, generateTutorComment, clearReports, feed, updatePrivacy } = WritingFeedState.useContainer()

  return <Wrapper>
    <div>
      <Filters />
      <Actions />
    </div>
    <PageSize>
      { pageSize !== 0 && <TextInput defaultValue={pageSize} onBlur={(e) => setPageSize(parseInt(e.target.value, 10))} /> }
      <div>items per page.</div>
    </PageSize>
    { !feed && <div>
      {loadingWritingCount ? 'Loading number of' : writingCount} writing to review
    </div> }
    <Content>
      { loading ? <Loader /> : writings.map((w, i) => <WritingWrapper key={w._id}>
        <EditableWriting {...w} quickButtons={[
          !w.private && <Button key='1' theme='primary' size='x-small' onClick={() => updatePrivacy(w._id, true)}>
            Make private
          </Button>,
           w.status !== 'published' && <Button key='2' size='x-small' theme='confirm' onClick={() => publish(w._id)}>
           Publish
         </Button>,
           w.status !== 'rejected' && <Button key='3' theme='red' size='x-small' onClick={() => flag(w._id)}>
           Flag
         </Button>
        ]} buttons={[
        <IconButton key='1' onClick={() => navigate(`/writing/${w._id}`)}>
          <Chat size='14px' color={colors.indigo} />
          See more
        </IconButton>,
        w.reports && w.reports.length > 0 && <IconButton key='2' onClick={() => clearReports(w._id)}>
          <Trash size='14px' color={colors.red} />
          Clear Reports
        </IconButton>,
        w.status !== 'rejected' && <IconButton key='2' onClick={() => flag(w._id)}>
          <Flag size='14px' color={colors.red} />
          Flag
        </IconButton>,
        w.status === 'published' && <IconButton key='3' onClick={() => unpublish(w._id)}>
          <Revert size='14px' color={colors.red} />
          Unpublish
        </IconButton>,
        w.status !== 'published' && <IconButton key='4' onClick={() => publish(w._id)}>
          <Checkmark size='14px' color={colors.indigo} />
          Publish
        </IconButton>,
        w.status !== 'rejected' && w.blogFeatured && <IconButton key='5' onClick={() => unfeature(w._id)}>
          <Star size='14px' color='#FFCE44' />
          Unfeature
        </IconButton>,
        w.status !== 'rejected' && !w.blogFeatured && <IconButton key='6' onClick={() => feature(w)}>
          <Star size='14px' />
          Feature
        </IconButton>,
        w.status !== 'rejected' && !w.blogFeatured && <IconButton key='7' onClick={() => tryAgain(w._id)}>
          <Revert size='14px' color={colors.red} />
          Try Again
        </IconButton>,
          w.private && <Button key='8' theme='primary' size='x-small' onClick={() => updatePrivacy(w._id, false)}>
          Make public
        </Button>,
          !w.private && <Button key='9' theme='primary' size='x-small' onClick={() => updatePrivacy(w._id, true)}>
          Make private
        </Button>
      ]} />
      { w.comments.length > 0 && <><h3>Comments ({ w.comments.length })</h3>
        <CommentsWrapper>
          { w.comments.map((c, index) => <CommentWrapper key={index} index={index}><EditableComment writingId={w._id} key={c._id} {...c} zookeeper={w.zookeeper} onDeleted={() => removeCommentWithId(i, c._id)} /></CommentWrapper> )}
        </CommentsWrapper></> }
          { !w.comments.find(c => c.auto) && w.status === 'pending' && (
            <GenerateAutoCommentButtonContainer><Button theme='primary' size='small' onClick={() => generateTutorComment(w._id)}>Generate Tutor Comment</Button></GenerateAutoCommentButtonContainer>
          )}
          <NewCommentWrapper><NewComment height='100px' text={`Hi ${w.zookeeper && w.zookeeper.username},`} onSent={c => addCommentToWriting(w._id, c)} writingId={w._id} /></NewCommentWrapper>
      </WritingWrapper>) }
    </Content>
    <div>
      <Actions />
    </div>
  </Wrapper>
}

AllWritingFeed.propTypes = {

}

AllWritingFeed.defaultProps = {

}

export default (props) => <WritingFeedState.Provider>
  <AllWritingFeed {...props} />
</WritingFeedState.Provider>
