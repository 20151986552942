import React from "react"
import WritingFeed from "../components/WritingFeed";

const WritingsPage = (props) => {
  if (typeof window === "undefined") return null

  return <WritingFeed {...props} />
}

export default WritingsPage
